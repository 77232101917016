<template>
  <svg
    width="1600px"
    height="900px"
    viewBox="0 0 1600 900"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <radialGradient
        cx="50%"
        cy="50%"
        fx="50%"
        fy="50%"
        r="50%"
        gradientTransform="translate(0.500000,0.500000),rotate(90.000000),scale(1.000000,0.980555),translate(-0.500000,-0.500000)"
        id="radialGradient-3"
      >
        <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
        <stop
          stop-color="#FFFFFF"
          stop-opacity="0.466182255"
          offset="100%"
        ></stop>
      </radialGradient>
      <path
        d="M14.399939,0 C9.09791629,0 4.80000019,4.20140023 4.80000019,9.38394479 C4.80000019,11.6930172 7.66412882,16.5600732 7.66412882,16.5600732 L14.399939,27.6000011 L20.7486549,16.5600732 C20.7486549,16.5600732 24.000001,11.6584597 24.000001,9.38394479 C24.000001,4.20140023 19.7019618,0 14.399939,0"
        id="path-4"
      ></path>
      <filter
        x="-101.6%"
        y="-70.7%"
        width="303.1%"
        height="248.3%"
        filterUnits="objectBoundingBox"
        id="filter-5"
      >
        <feMorphology
          radius="1"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="0"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="5.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Casinos"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Caesars-Palace">
        <g id="path-caesars">
          <g id="Route" stroke-linecap="round" stroke-linejoin="round">
            <filter id="glow-1" x="-2" y="-2" width="200" height="200">
              <feGaussianBlur stdDeviation="4" />
            </filter>
            <path
              filter="url(#glow-1)"
              stroke="#FFFFFF"
              stroke-width="10.00000024"
              d="M833.600005,260.200001 L838.000005,265.400001 L842.800005,262.800001 C841.777085,257.242579 842.941732,253.642578 846.293945,252 L849.178711,235.405273 L833.200005,215.621094 L826.5,212.463867 L800,217.483398 L786.860352,215.621094 L741.084961,213.463867 L740.084961,238.931934 L753.220703,296.260742 L742.448242,332.446289 L741.084961,515.405273 L750.597656,546.177734 L751.919922,560.792969 L749.597656,592.21875 C743.013672,613.534505 740.176107,629.730143 741.084961,640.805664 L740.084961,708.779297 L773.847656,708.779297 L773.847656,721.745545 L749.597656,726.311523 L689,726.311523 C685.26667,726.340169 683.400005,727.882812 683.400005,730.939453 C683.400005,733.996094 683.400005,738.247744 683.400005,743.694404 L689,744"
              class="path-glow"
            ></path>
            <path
              stroke="#FFFFFF"
              stroke-width="6.00000024"
              d="M833.600005,260.200001 L838.000005,265.400001 L842.800005,262.800001 C841.777085,257.242579 842.941732,253.642578 846.293945,252 L849.178711,235.405273 L833.200005,215.621094 L826.5,212.463867 L800,217.483398 L786.860352,215.621094 L741.084961,213.463867 L740.084961,238.931934 L753.220703,296.260742 L742.448242,332.446289 L741.084961,515.405273 L750.597656,546.177734 L751.919922,560.792969 L749.597656,592.21875 C743.013672,613.534505 740.176107,629.730143 741.084961,640.805664 L740.084961,708.779297 L773.847656,708.779297 L773.847656,721.745545 L749.597656,726.311523 L689,726.311523 C685.26667,726.340169 683.400005,727.882812 683.400005,730.939453 C683.400005,733.996094 683.400005,738.247744 683.400005,743.694404 L689,744"
              class="path-line"
            ></path>
          </g>
          <g id="Ending-Point" transform="translate(673.800004, 729.000000)">
            <circle
              id="Oval-back"
              fill="url(#radialGradient-3)"
              cx="14.4000006"
              cy="14.6000011"
              r="14.4000006"
            ></circle>
            <circle
              id="Oval-back"
              fill="url(#radialGradient-3)"
              cx="14.4000006"
              cy="14.6000011"
              r="9.60000038"
            ></circle>
            <circle
              id="Oval"
              fill="#5A5A5A"
              cx="14.4000006"
              cy="14.6000011"
              r="4.80000019"
            ></circle>
          </g>
          <image
            id="Bitmap"
            x="550"
            y="678"
            width="157"
            height="141"
            href="@/assets/images/icons/end.png"
          ></image>
          <g id="Starting-Point" transform="translate(818.800004, 233.000000)">
            <circle
              id="Oval-back"
              fill="url(#radialGradient-3)"
              cx="14.4000006"
              cy="27.6000011"
              r="14.4000006"
            ></circle>
            <circle
              id="Oval-back"
              fill="url(#radialGradient-3)"
              cx="14.4000006"
              cy="27.6000011"
              r="9.60000038"
            ></circle>
            <circle
              id="Oval"
              fill="#5A5A5A"
              cx="14.4000006"
              cy="27.6000011"
              r="4.80000019"
            ></circle>
            <g id="Fill-1">
              <use
                fill="black"
                fill-opacity="1"
                filter="url(#filter-5)"
                xlink:href="#path-4"
              ></use>
              <use
                fill="#FFFFFF"
                fill-rule="evenodd"
                xlink:href="#path-4"
              ></use>
            </g>
          </g>
          <text
            id="Caesars-Palace"
            font-family="sans-serif"
            font-size="14.4000006"
            font-weight="normal"
            line-spacing="15.6000006"
            fill="#FFFFFF"
          >
            <tspan x="861" y="265">Caesars Palace</tspan>
          </text>
          <rect
            id="Rectangle"
            fill="#000000"
            x="1599"
            y="899"
            width="1"
            height="1"
          ></rect>
          <rect
            id="Rectangle"
            fill="#000000"
            x="0"
            y="0"
            width="1"
            height="1"
          ></rect>
          <rect
            id="Rectangle"
            fill="#000000"
            x="1599"
            y="0"
            width="1"
            height="1"
          ></rect>
          <rect
            id="Rectangle"
            fill="#000000"
            x="0"
            y="899"
            width="1"
            height="1"
          ></rect>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CaesarsPalacePath",
  mounted() {
    var path = document.querySelector(".path-line");
    var length = path.getTotalLength();
    path.style.transition = path.style.WebkitTransition = "none";
    path.style.strokeDasharray = length + " " + length;
    path.style.strokeDashoffset = length;
    path.getBoundingClientRect();
    path.style.transition = path.style.WebkitTransition =
      "stroke-dashoffset 2s ease-in-out";
    path.style.strokeDashoffset = "0";

    var pathGlow = document.querySelector(".path-glow");
    var lengthGlow = pathGlow.getTotalLength();
    pathGlow.style.transition = pathGlow.style.WebkitTransition = "none";
    pathGlow.style.strokeDasharray = lengthGlow + " " + lengthGlow;
    pathGlow.style.strokeDashoffset = lengthGlow;
    pathGlow.getBoundingClientRect();
    pathGlow.style.transition = pathGlow.style.WebkitTransition =
      "stroke-dashoffset 2s ease-in-out";
    pathGlow.style.strokeDashoffset = "0";
  },
};
</script>

<style lang="scss" scoped>
</style>